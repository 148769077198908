@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg font-bold;
  }
  h4 {
    @apply text-base font-bold mt-4 mb-2 md:mt-8 md:mb-4;
  }
  p {
    @apply pb-4;
  }
}

/* .root {
  display: block;
  position: relative;
}

.lqip {
  image-rendering: pixelated;
  width: 100%;
  opacity: 1;
  transition: opacity 50ms 100ms ease-out;
}

.root[data-has-aspect="true"] .lqip {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  background-size: 100% 100%;
}

.root[data-is-loaded="true"] {
  opacity: 0;
}

.img {
  image-rendering: auto;
  display: block;
  width: 100%;
}

.root[data-has-aspect="true"] .img {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
} */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
